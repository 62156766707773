import React, { useMemo } from "react";
import Accordion from "@components/Accordion/Accordion";

export default function AccordionComponent({ text, title, children, isOpen }) {
  const renderContent = useMemo(() => {
    return children
      ? children
      : text?.map((item, index) => {
          let classes = "accordion-text";
          classes += item.strong ? " accordion-text--strong" : "";

          return <p className={classes}>{item.text}</p>;
        });
  }, [children, text]);
  return (
    <Accordion
      title={title}
      children={renderContent}
      defaultExpanded={isOpen}
    />
  );
}
