import React, { useEffect, useCallback, useMemo, useState } from "react";
import { Power3, gsap } from "gsap/all";
import Observer from "react-intersection-observer";
import { set } from "react-ga";
import numberToCurrency from "@utils/numerToCurrency";

export const DetailsList = ({ slice }) => {
  useEffect(() => {
    initGSAP();
  }, [initGSAP]);

  const handleContentVisibility = useCallback(
    (inView, entry) => {
      if (inView) {
        animateContent();
      }
    },
    [animateContent]
  );
  const headers = [
    "Year",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
    "AMC",
    "Bitcoin",
    "S&P 500",
  ];
  const data = [
    {
      year: 2024,
      values: [
        0.45,
        17.03,
        4.47,
        -7.97,
        2.62,
        -5.38,
        -2.26,
        -5.56,
        "-",
        "-",
        "-",
        "-",
        //
        1.3,
        38.43,
        18.42,
      ],
    },
    {
      year: 2023,
      values: [
        11.74,
        3.65,
        5.13,
        1.53,
        -2.44,
        2.50,
        -0.59,
        -2.17,
        -1.19,
        7.37,
        4.28,
        5.91,
        //

        40.87,
        157.04,
        24.23,
      ],
    },
    {
      year: 2022,
      values: [
        -1.44,
        1.00,
        6.17,
        -6.36,
        -2.36,
        0.14,
        3.35,
        -0.79,
        -0.87,
        2.43,
        0.35,
        -4.25,
        //
        -3.2,
        -64.75,
        -19.44,
      ],
    },
  ];
  const renderData = useMemo(() => {
    return data.map((row, index) => (
      <tr key={index}>
        <td>{row.year}</td>
        {row.values.map((value, idx) => {
          const isEmpty = value === "-";
          const isNegative = Math.sign(value) === -1;
          const result = !isEmpty ? `${numberToCurrency(value)} %` : value;
          debugger;
          return (
            <td key={idx} className={isNegative ? "red" : ""}>
              {result}
            </td>
          );
        })}
      </tr>
    ));
  }, [data]);
  return (
    <Observer
      threshold="0.1"
      onChange={handleContentVisibility}
      className="wrapper container indents"
    >
      <table className="table_static">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>{renderData}</tbody>
      </table>
    </Observer>
  );

  function initGSAP() {
    gsap.set([".table_static"], {
      opacity: 0,
      y: 100,
    });
  }

  function animateContent() {
    gsap.to(".table_static", {
      opacity: 1,
      y: 0,
      ease: Power3.easeOut,
      delay: 0.4,
    });
  }
};

export default DetailsList;
