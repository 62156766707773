import browserStorage from "@utils/browserStorage";

const getLocale = (defaultLocale = "en-US") => {
  const locale = browserStorage.get("lang");
  return locale || defaultLocale;
};

const numberToCurrency = (
  number,
  { locale = getLocale(), minimumFractionDigits, maximumFractionDigits } = {}
) => {
  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: minimumFractionDigits || undefined,
    maximumFractionDigits: maximumFractionDigits || undefined,
  });

  return formatter.format(number);
};

export default numberToCurrency;
