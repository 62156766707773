import React, { useEffect, useState, useCallback } from "react";
import { graphql } from "gatsby";
import Layout from "@components/layout";
import { HeroComponent, TextureComponent } from "@slices/index.js";
import browserStorage from "@utils/browserStorage";
import "../styles/3iq.scss";
import DetailsList from "@components/products/3iq/DetailsList";
import TextDescription from "@components/products/3iq/TextDescription";
import Chart from "@components/products/3iq/Chart";
import Resources from "@components/products/3iq/Resources";
import TableAssets from "@components/products/3iq/TableAssets";
import ProductPrompt from "@components/products/3iq/ProductPrompt";
import TableStatic from "@components/products/3iq/TableStatic";
import HeroComponent3Iq from "@components/products/3iq/HeroComponent3Iq";
export const Product3Iq = ({ data }) => {
  const [isShowPrompt, setIsShowPrompt] = useState(true);
  useEffect(() => {
    if (browserStorage.get("residence") && browserStorage.get("investor"))
      setIsShowPrompt(false);
  }, [setIsShowPrompt]);

  if (!data) return null;

  browserStorage.setLanguage(data.prismicHeaderitem?.lang);

  const header = data.prismicHeaderitem ?? {};
  const footer = data.prismicFooteritem ?? {};
  const { lang, type, url } = header ?? {};
  const dataForTextureComponent = data.prismicHomepage?.data?.body[9];
  const alternateLanguages = header?.alternate_languages ?? [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };

  const dataHeroComponent = data.prismic3Iq?.data?.body[0];
  const TextDescription_1 = data.prismic3Iq?.data?.body[1];
  const TextDescription_2 = data.prismic3Iq?.data?.body[2];
  const chartInfo = data.prismic3Iq?.data?.body[3];
  const resourcesLinks = data.prismic3Iq?.data?.body[4];
  const TextDescription_3 = data.prismic3Iq?.data?.body[5];
  const TextDescription_4 = data.prismic3Iq?.data?.body[6];
  const tableDetails = data.prismic3Iq?.data?.body[7];
  const productDetails = data.prismic3Iq?.data?.body[8];
  const resourcesDetails = data.prismic3Iq?.data?.body[9];
  const promptDetails = data.prismic3Iq?.data?.body[10];

  return (
    <Layout
      activeDocMeta={activeDoc}
      body={header}
      bodyFooter={footer}
      fullPage={false}
    >
      <div className="product3iPage">
        {isShowPrompt && <ProductPrompt slice={promptDetails} />}
        {/* <HeroComponent slice={dataHeroComponent} /> */}
        <HeroComponent3Iq slice={dataHeroComponent} />
        <TextDescription slice={TextDescription_1} />
        <TextDescription slice={TextDescription_2} />
        <Chart slice={chartInfo} />
        <TableStatic />
        <Resources slice={resourcesLinks} /> {/* Downloads */}
        <TextDescription slice={TextDescription_3} /> {/* about 3iq */}
        <TextDescription slice={TextDescription_4} /> {/* about Smart*/}
        <TableAssets slice={tableDetails} />
        <DetailsList slice={productDetails} />
        <DetailsList slice={resourcesDetails} />
        <TextureComponent slice={dataForTextureComponent} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query threeIqQuery($lang: String) {
    prismicHeaderitem(lang: { eq: $lang }) {
      lang
      type
      url
      alternate_languages {
        lang
        type
      }
      data {
        button {
          text
        }
        item1 {
          text
        }
        item2 {
          text
        }
        item3 {
          text
        }
        aboutus {
          aboutus_name {
            text
          }
        }
        item4 {
          text
        }
        item5 {
          text
        }
        item6 {
          text
        }
        item7 {
          text
        }
        solutions {
          solution_name {
            text
          }
        }
        products {
          product_name {
            text
          }
        }
        cookie_consent {
          raw
        }
      }
    }
    prismicHomepage(lang: { eq: $lang }) {
      lang
      url
      data {
        body {
          ... on PrismicHomepageBodyTextureSection {
            id
            slice_type
            primary {
              buttontext {
                text
              }
              emailtext {
                text
              }
              title {
                text
              }
            }
          }
        }
      }
    }
    prismic3Iq(lang: { eq: $lang }) {
      lang
      data {
        body {
          ... on Prismic3iqBodyHeroSection {
            primary {
              title {
                text
              }
              subtitle {
                text
                raw
              }
              background_image {
                alt
                url
              }
              prepend_logo {
                alt
                url
              }
            }
          }
          ... on Prismic3iqBodyMarketPerformance3iq {
            primary {
              stat_title {
                text
              }
              title {
                text
              }
              card_title {
                text
              }
              subtitle {
                text
              }
              chart {
                url
                alt
              }
              btn_label {
                text
              }
              link {
                text
              }
            }
            items {
              label {
                text
              }
              value {
                text
              }
            }
          }
          ... on Prismic3iqBodyProductDetails {
            primary {
              title {
                text
              }
            }
            items {
              label {
                text
              }
              value {
                text
              }
            }
          }
          ... on Prismic3iqBodyTitleDescription {
            primary {
              description {
                text
                raw
              }
              title {
                text
              }
              expanded
            }
          }
          ... on Prismic3iqBodyLinks {
            primary {
              title {
                text
              }
              expanded
            }
            items {
              link {
                text
              }
              label {
                text
              }
            }
          }
          ... on Prismic3iqBodyTable {
            items {
              column1_data {
                text
              }
              column2_data {
                text
              }
              column3_data {
                text
              }
              column4_data {
                text
              }
              column5_data {
                text
              }
              link {
                url
                target
              }
            }
            primary {
              column1_name {
                text
              }
              column2_name {
                text
              }
              column3_name {
                text
              }
              column4_name {
                text
              }
              column5_name {
                text
              }
              total_label {
                text
              }
              total_value {
                text
              }
            }
          }
          ... on Prismic3iqBodyProductPrompt {
            primary {
              question1_options {
                raw
              }
              question2_label {
                text
              }
              question2_options {
                raw
              }
              subtitle {
                text
              }
              terms {
                text
              }
              title {
                text
                raw
              }
              question1_label {
                text
              }
              list_title {
                text
              }
              list_options {
                raw
              }
            }
          }
        }
      }
    }
    prismicFooteritem(lang: { eq: $lang }) {
      url
      type
      lang
      alternate_languages {
        lang
        type
      }
      data {
        about {
          text
        }
        allrights {
          text
        }
        item1 {
          text
        }
        item2 {
          text
        }
        item3 {
          text
        }
        item4 {
          text
        }
        item5 {
          text
        }
        item6 {
          text
        }
        item7 {
          text
        }
        privacy {
          text
        }
        terms {
          text
        }
        clientinformation {
          text
        }
        impressum {
          text
        }
      }
    }
  }
`;

export default Product3Iq;
