import React, { useEffect, useCallback, useMemo } from "react";
import { Power3, gsap } from "gsap/all";
import Observer from "react-intersection-observer";
import AccordionText from "@components/Accordion/AccordionText";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { ReactComponent as ArrowDownload } from "@images/circle-down-solid.svg";

export const AboutComponent = ({ slice }) => {
  const title = slice.primary?.title?.text;
  const items = slice?.items;
  const isExpanded = slice.primary?.expanded;
  useEffect(() => {
    initGSAP();
  }, []);

  const handleContentVisibility = useCallback(
    (inView, entry) => {
      if (inView) {
        animateContent();
      }
    },
    [animateContent]
  );

  const renderContent = useMemo(() => {
    return items?.map((item, index) => {
      return (
        <div key={index} className="item">
          <Button
            href={item?.link?.text}
            color="primary"
            className="btn"
            target="_blank"
          >
            <ArrowDownload />
            <span className="item_label">{item?.label?.text}</span>
          </Button>
          <Divider orientation="vertical" flexItem />
        </div>
      );
    });
  }, [items]);

  return (
    <Observer
      threshold="0.1"
      onChange={handleContentVisibility}
      className="wrapper container"
    >
      <div className="resource indents">
        <AccordionText
          isOpen={isExpanded}
          title={title}
          children={<div className="resource_list">{renderContent}</div>}
        />
        <Divider className="divider" light />
      </div>
    </Observer>
  );

  function initGSAP() {
    gsap.set([".accordion"], {
      opacity: 0,
      y: 100,
      ease: Power3.easeOut,
    });
  }

  function animateContent() {
    gsap.to(".accordion", {
      opacity: 1,
      y: 0,
      ease: Power3.easeOut,
      delay: 0.2,
    });
  }
};

export default AboutComponent;
